import { ref, onMounted, onBeforeUnmount } from 'vue';
import eventBus from '@/eventBus.js';
import http from '@/http.js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useApplications() {
    const applications = ref([]);
    const applicationsMethode = ref([]);
    const applicationData = ref({});
    const accordionOpen = ref(true);
    const auteurs = ref([]);
    const auteurData = ref({});

    const getApplications = () => {
        http.getApplications().then((data) => {
            applications.value = data;
        });
    };

    const getApplicationsMethode = () =>{
        http.getApplicationsMethode().then((data) => {
            applicationsMethode.value = data;
        });
    };

    const getAuteurs = () => {
        http.getAuteurs().then((data) => {
            auteurs.value = data;
        });
    };

    const toggleAccordion = () => {
        accordionOpen.value = !accordionOpen.value;
    };

    onMounted(() => {
        getApplications();
        const handleReloadApplications = () => {
            getApplications();
        };
        eventBus.on("reloadApplications", handleReloadApplications);
        onBeforeUnmount(() => {
            eventBus.off("reloadApplications", handleReloadApplications);
        });

        getApplicationsMethode();
        const handleReloadApplicationsMethodes = ()=>{
            getApplicationsMethode()
        };
        eventBus.on("reloadApplicationsMethode", handleReloadApplicationsMethodes);
        onBeforeUnmount(() => {
            eventBus.off("reloadApplicationsMethode", handleReloadApplicationsMethodes);
        });

        getAuteurs();
        const handleReloadAuteurs = () => {
            getAuteurs();
        };
        eventBus.on("reloadAuteurs", handleReloadAuteurs);
        onBeforeUnmount(() => {
            eventBus.off("reloadAuteurs", handleReloadAuteurs);
        });
    });

    return {
        applications,
        applicationsMethode,
        applicationData,
        auteurs,
        auteurData,
        accordionOpen,
        toggleAccordion,
        getApplications,
        getApplicationsMethode,
        getAuteurs,
    };
}