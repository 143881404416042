<template>
  <transition name="modal">
    <b-card class="modal-container">
      <form @submit.prevent="emitModifierRef" id="modifReference">
        <div class="titreModal pt-2 pb-2">
          <h5>Modifier la réference séléctionnée</h5>
        </div>
        <div id="accordionExample2">
          <b-card class=" formModal mr-4 ml-4">
            <b-form-group class="labelTitre" id="input-group-1" label-size="sm" label="Titre réf:" label-for="input-horizontal">
              <Field name="titre" type="text" v-model="titre" class="form-control" />
              <ErrorMessage name="titre" class="text-danger" />
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Type réf:" label-for="input-2">
                  <Field as="select" name="typeRef" v-model="typeRef.code" class="form-control">
                    <option v-for="t in typeRefs" v-bind:value="t.code" v-bind:key="t.code">{{t.signification}}</option>
                  </Field>
                  <ErrorMessage name="typeRef" class="text-danger" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Document source:" label-for="input-2">
              <b-form-input id="input-3" v-model="document" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-3" label-size="sm" label="Pagination:" label-for="input-3">
                  <b-form-input id="input-4" v-model="pagination" type="text" size="sm">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-4" label-size="sm" label="Année publication:" label-for="input-5">
                  <Field name="annee" type="text" v-model="annee" class="form-control" />
                  <ErrorMessage name="annee" class="text-danger" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre" id="input-group-5" label-size="sm" label="Mots clés:" label-for="input-6">
              <b-form-input id="input-6" v-model="motCle" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-6" label-size="sm" label="N° Identification:" label-for="input-7">
                  <b-form-input id="input-7" v-model="identification" type="text" size="sm">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-7" label-size="sm" label="Disponibilité:" label-for="input-8">
                  <b-form-input id="input-8" v-model="disponibilite" type="text" size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-button class="listAuteurs mt-3" data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse6" id="reference">Affecter des auteurs</b-button>
            <div id="collapse8" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample2">
              <b-card-body>
                <AuteursListFromRef
                    :auteurs="auteurs"
                    :auteursRef="auteursRef"
                    :AuteursFromRef="AuteursToInsert"
                />
              </b-card-body>
            </div>
          </b-card>
        </div>
        <div class="bouton">
          <b-button pill type="submit" class="valid m-2">Valider</b-button>
          <b-button pill class="closed m-2" @click="$emit('close')" id="close">Annuler</b-button>
        </div>
      </form>
    </b-card>
  </transition>
</template>
<script>
import { useModifierRefBiblio } from "@/composition/RefBiblio/modifierRefBiblio"
import { defineComponent } from 'vue';
import {ErrorMessage, Field} from "vee-validate";
import AuteursListFromRef from "@/components/Applications/RefBiblio/AuteursListFromRef.vue";

export default defineComponent({
  name: 'ModifRef',
  components: {
    Field,
    ErrorMessage,
    AuteursListFromRef,
  },
  props:{
    id:{
      type: Number
    }
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props, {emit}) {
   return useModifierRefBiblio(props, emit);
  },
});
</script>

<style>

</style>