<template>
  <div v-if="show" :class="['notification', type]" @click="hide">
    <p>{{ message }}</p>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'NotificationMessage',

  props: {
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'success'
    },
    duration: {
      type: Number,
    }
  },


  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props) {
    const show = ref(true);

    const hide = () => {
      show.value = false;
    };

    const messageType = ref('');

    onMounted(() => {
      switch (props.type) {
        case 'success':
          messageType.value = 'success';
          break;
        case 'danger':
          messageType.value = 'danger';
          break;
        default:
          messageType.value = 'info';
          break;
      }

      setTimeout(() => {
        show.value = false;
      }, props.duration);
    });

    return{
      show,
      hide,
      messageType,
    }
  }
}
</script>

<style scoped>
.notification {
  padding: 10px 10px 0px 10px;
  border-radius: 5px;
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  cursor: pointer;
  transition: all 0.3s ease;
}

.notification.success {
  background-color: #d4edda;
  color: #155724;
}

.notification.danger {
  background-color: #f8d7da;
  color: #721c24;
}

.notification.info {
  background-color: #cce5ff;
  color: #004085;
}
</style>