<template>
  <div class="auteurList">
    <div v-if="showTable" class="tableSecondElement">
      <table class="table table-bordered table-striped table-hover table-sm">
        <thead>
          <tr>
            <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="auteur in paginatedAuteurs" :key="auteur.idAuteur" @click="toggleSelected(auteur)"
              :class="{ 'table-active': isSelected(auteur.idAuteur) }">
            <td class="text-center">
              <input type="checkbox" v-model="selectedAuteurs" :value="auteur.idAuteur" disabled>
            </td>
            <td class="text-center">{{ auteur.idAuteur }}</td>
            <td class="text-center">{{ auteur.nom }}</td>
            <td class="text-center">{{ auteur.prenom }}</td>
            <td class="text-center">{{ auteur.email }}</td>
            <td class="text-center">{{ auteur.organisme }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-row v-if="showTable">
      <b-col cols="5" class="icon">
        <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
          <b-input-group size="sm">
            <b-form-input id="filter-input" v-model="filtre_auteurs" type="search" placeholder="Rechercher Auteur"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filtre_auteurs" @click="filtre_auteurs = ''"><i class="fa-solid fa-arrows-rotate"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <div class="pagination">
          <p>{{nbResult}} auteurs(s) sur {{totalRows}}</p>
        </div>
        <div class="pagination">
          <b-pagination class="customPagination"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        aria-controls="my-card"
                        size="sm"
                        align="right">
          </b-pagination>
        </div>
      </b-col>
    </b-row>
    <div v-if="auteursRef.length!==0" class="auteursApp mt-3">
      <b-card class="card-list">
        <p style="color: white"><strong><u>Affectation des auteurs d'une référence bibliographique</u></strong></p>
        <AuteurListFrom
            :auteursApp="displayedAuteurs"
            @deleteAuteur="supprimerAuteur">
        </AuteurListFrom>
      </b-card>
      <div class="bouton">
        <b-button size="sm" v-on:click="saveSelection" class="btn-ajout">Enregistrer la séléction</b-button>
      </div>
    </div>
  </div>
</template>
<script>

import {defineComponent} from "vue";
import {useAuteursList} from "@/composition/RefBiblio/auteursListFromRef";
import AuteurListFrom from "@/components/Applications/Auteur/AuteurListFrom.vue";

export default defineComponent({
  name: "AuteursList",
  components: {AuteurListFrom},

  props:{
    auteurs:{
      type:Array,
      required:true
    },
    auteursRef:{
      type:Array,
      required:true
    },
    lectureSeule:{
      type:Boolean,
      default:true
    },
    AuteursFromRef: Function
  },

  setup(props) {
    return useAuteursList(props);
  },
});

</script>
<style scoped>

.auteursApp{
  text-align: center;
  justify-content: center;
}
</style>