<template>
  <div class="auteurList">
    <div class="tableSecondElement">
      <table class="table table-bordered table-striped table-hover table-sm">
        <thead>
          <tr>
            <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="auteur in auteursApp" :key="auteur.idAuteur">
            <td class="text-center">{{ auteur.idAuteur }}</td>
            <td class="text-center">{{ auteur.nom }}</td>
            <td class="text-center">{{ auteur.prenom }}</td>
            <td class="text-center">{{ auteur.email }}</td>
            <td class="text-center">{{ auteur.organisme }}</td>
            <td class="text-center">
              <a @click="supprimerAuteur(auteur)" class="icon_delete" title="supprimer l'auteur">
                <i class="fa-solid fa-trash-can"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, toRefs } from 'vue';

export default defineComponent({
  name: "AuteursList",

  props:{
    auteursApp:{
      type:Array,
      required:true
    },
    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  setup(props, { emit }) {
    const fields = ref([
      { key: 'idAuteur', label: 'Id', class: 'text-center' },
      { key: 'nom', label: 'Nom', class: 'text-center' },
      { key: 'prenom', label: 'Prénom', class: 'text-center' },
      { key: 'email', label: 'Mail', class: 'text-center' },
      { key: 'organisme', label: 'Organisme', class: 'text-center' },
      { key: "actions", label: "Actions", class: "text-center" }
    ]);

    const supprimerAuteur = (auteur) => {
      emit("deleteAuteur", auteur);
    };

    return {
      ...toRefs(props),
      fields,
      supprimerAuteur
    };
  }
});
</script>
<style scoped>

</style>