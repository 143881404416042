import { ref, computed, watchEffect, onMounted, onBeforeUnmount, watch } from "vue";
import eventBus from '@/eventBus.js'
import http from "@/http";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useAuteursList(props) {
    const perPage = ref(10);
    const currentPage = ref(1);
    const selectedAuteurs = ref([]);
    const selectMode = ref("multi");
    const selectedAuteurId = ref(null);
    const showTable = ref(true);
    const filtre_auteurs = ref("");
    const nbResult = ref(0);
    const totalRows = computed(() => props.auteurs.length);
    const auteurs = ref(props.auteursRef);
    const idRef = ref(null);

    const fields = [
        { key: "selection", label: "Sélection", class: "text-center" },
        { key: "idAuteur", label: "Id", class: "text-center" },
        { key: "nom", label: "Nom", class: "text-center" },
        { key: "prenom", label: "Prénom", class: "text-center" },
        { key: "email", label: "Mail", class: "text-center" },
        { key: "organisme", label: "Organisme", class: "text-center" },
    ];

    const supprimerAuteur = (auteur) => {
        // Supprimer l'auteur de la liste sélectionnée (auteurs)
        auteurs.value = auteurs.value.filter(a => a.idAuteur !== auteur.idAuteur);
        clearSelected();
    };

    const displayedAuteurs = computed(() => {
        const combinedAuteurs = [...props.auteursRef];
        // Ajouter les auteurs sélectionnés (non déjà dans auteursRef)
        auteurs.value.forEach(auteur => {
            if (!combinedAuteurs.some(a => a.idAuteur === auteur.idAuteur)) {
                combinedAuteurs.push(auteur);
            }
        });
        return combinedAuteurs;
    });

    const getAuteursFromRef = (id) => {
        http.getAuteursFromRef(id).then((data) => {
            auteurs.value = data;
        });
    };

    const lastSelectedAuteurId = computed(() => {
        if (selectedAuteurs.value.length > 0) {
            return selectedAuteurs.value[selectedAuteurs.value.length - 1];
        }
        return null;
    });

    const toggleSelected = (auteur) => {
        const index = selectedAuteurs.value.indexOf(auteur.idAuteur);
        if (index === -1) {  // Si l'auteur n'est pas déjà sélectionné
            selectedAuteurs.value.push(auteur.idAuteur);
            if (!auteurs.value.some(a => a.idAuteur === auteur.idAuteur)) {
                // Ajouter à la liste si l'auteur n'est pas déjà dans auteursRef
                auteurs.value.push(auteur);
            }
        } else {
            selectedAuteurs.value.splice(index, 1);
            auteurs.value = auteurs.value.filter(a => a.idAuteur !== auteur.idAuteur);
        }
        selectedAuteurId.value = auteur.idAuteur;
    };

    const isSelected = (auteurId) => {
        return selectedAuteurs.value.includes(auteurId);
    };

    const clearTable = () => {
        auteurs.value = [];
        clearSelected();
    };

    const clearSelected = () => {
        selectedAuteurs.value = [];
    };

    const filteredAuteurs = computed(() => {
        if (!filtre_auteurs.value) return props.auteurs;

        return props.auteurs.filter(
            (auteur) =>
                (auteur.nom &&
                    auteur.nom.toLowerCase().includes(filtre_auteurs.value.toLowerCase())) ||
                (auteur.prenom &&
                    auteur.prenom.toLowerCase().includes(filtre_auteurs.value.toLowerCase())) ||
                (auteur.email &&
                    auteur.email.toLowerCase().includes(filtre_auteurs.value.toLowerCase())) ||
                (auteur.organisme &&
                    auteur.organisme.toLowerCase().includes(filtre_auteurs.value.toLowerCase()))
        );
    });

    watch(lastSelectedAuteurId, (newVal) => {
        if (newVal !== null) {
            eventBus.emit("updateAuteurSelected", newVal);
        }
    });

    watchEffect(() => {
        nbResult.value = filteredAuteurs.value.length;
        currentPage.value = 1;
    });

    const paginatedAuteurs = computed(() => {
        const start = (currentPage.value - 1) * perPage.value;
        const end = start + perPage.value;
        return filteredAuteurs.value.slice(start, end);
    });

    const updateAuteurs = () => {
        if (!filtre_auteurs.value) {
            nbResult.value = props.auteurs.length;
            return;
        }
        nbResult.value = filteredAuteurs.value.length;
    };

    const saveSelection = () => {
        console.log(displayedAuteurs.value)
        props.AuteursFromRef(displayedAuteurs.value);
        showTable.value = false;
    };

    onMounted(() => {
        eventBus.on("updateRefSelected", (id) => {
            getAuteursFromRef(id);
            idRef.value = id;
        });
    });

    onBeforeUnmount(() => {
        eventBus.off("updateRefSelected");
    });

    return {
        perPage,
        currentPage,
        selectMode,
        showTable,
        selectedAuteurId,
        selectedAuteurs,
        filtre_auteurs,
        nbResult,
        fields,
        filteredAuteurs,
        paginatedAuteurs,
        totalRows,
        updateAuteurs,
        lastSelectedAuteurId,
        auteurs,
        idRef,
        toggleSelected,
        isSelected,
        supprimerAuteur,
        displayedAuteurs,
        clearTable,
        clearSelected,
        saveSelection
    };
}