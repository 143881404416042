import { ref, onMounted, onBeforeUnmount } from 'vue';
import http from '@/http.js';
import eventBus from '@/eventBus.js';
import { useForm} from 'vee-validate';
import * as yup from 'yup';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useAjoutRefBiblio({ emit }) {
    const locale = ref('fr-FR');
    const idRef = ref('');
    const titre = ref('');
    const document = ref('');
    const pagination = ref('');
    const annee = ref('');
    const motCle = ref('');
    const identification = ref('');
    const disponibilite = ref('');
    const typeRefs = ref([]);
    const auteursToAdd = ref([]);
    const auteurs = ref([]);
    const auteursRef = ref([]);
    const typeRef = ref({});
    const refBiblioData = ref({});

    const message = ref("");
    const showNotification = ref(false);
    const type = ref("info");
    const duration = ref(5000);

    const schema = yup.object({
        titre: yup.string().required('Ce champ est obligatoire'),
        annee: yup.string().required('Ce champ est obligatoire'),
        typeRef: yup.string().required('Ce champ est obligatoire'),
    });

    const { handleSubmit } = useForm({
        validationSchema: schema,
    });

    const AuteursToInsert = (auteursRef) => {
        auteursToAdd.value = auteursRef;
    };

    onMounted(() => {
        loadTypeRefs();
        loadAuteurs();

        const handleInsertRef = handleSubmit(async (values) => {
            refBiblioData.value = {
                idRef: idRef.value,
                titre: values.titre,
                typeRef: values.typeRef,
                document: document.value,
                pagination: pagination.value,
                annee: values.annee,
                motCle: motCle.value,
                identification: identification.value,
                disponibilite: disponibilite.value,
                auteurs: auteursToAdd.value,
            };

            try {
                const data = await http.insertRef(refBiblioData.value);
                idRef.value = data;
                emit('close');
                eventBus.emit('reloadRefs');
                emit('notification',{
                    showNotification : true,
                    message : "Référence ajoutée avec succès!",
                    type : "success",
                })
            } catch (error) {
                emit('notification',{
                    showNotification : true,
                    message : 'Erreur lors de l\'ajout de la référence.',
                    type : "danger",
                })
            }
        });

        eventBus.on('insertRefBiblio', handleInsertRef);

        onBeforeUnmount(() => {
            eventBus.off('insertRefBiblio', handleInsertRef);
        });
    });

    const loadTypeRefs = () => {
        http.getTypeRefs().then((data) => {
            typeRefs.value = data;
        });
    };

    const loadAuteurs = () => {
        http.getAuteurs().then((data) => {
            auteurs.value = data;
        });
    };

    const emitInsertRef = () => {
        eventBus.emit('insertRefBiblio');
    };

    return {
        locale,
        idRef,
        titre,
        document,
        pagination,
        annee,
        motCle,
        identification,
        disponibilite,
        typeRefs,
        typeRef,
        auteurs,
        auteursRef,
        auteursToAdd,
        refBiblioData,
        emitInsertRef,
        AuteursToInsert,
        message,
        showNotification,
        type,
        duration
    };
}