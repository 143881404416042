import { ref, onMounted, onBeforeUnmount } from 'vue';
import http from '@/http.js';
import eventBus from '@/eventBus.js';
import { useForm } from 'vee-validate';
import * as yup from 'yup';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useModifierRefBiblio(props, emit) {
    const locale = ref('fr-FR');
    const idRef = ref('');
    const titre = ref('');
    const document = ref('');
    const pagination = ref('');
    const annee = ref('');
    const motCle = ref('');
    const identification = ref('');
    const disponibilite = ref('');
    const typeRefs = ref([]);
    const auteursToAdd = ref([]);
    const auteursRef = ref([]);
    const auteurs = ref([]);
    const typeRef = ref({});
    const localId = ref(props.id);

    const message = ref("");
    const showNotification = ref(false);
    const type = ref("info");
    const duration = ref(5000);

    const schema = yup.object({
        titre: yup.string().required('Ce champ est obligatoire'),
        annee: yup.string().required('Ce champ est obligatoire'),
        typeRef: yup.string().required('Ce champ est obligatoire'),
    });

    const { handleSubmit } = useForm({
        validationSchema: schema,
    });

    const updateModalData = (idRef) => {
        http.loadRef(idRef).then((data) => {
            titre.value = data.titre;
            document.value = data.document;
            pagination.value = data.pagination;
            annee.value = data.annee;
            motCle.value = data.motCle;
            identification.value = data.identification;
            disponibilite.value = data.disponibilite;
            typeRef.value = data.typeRef;
            auteursRef.value = data.auteurs;
        });
    };

    const AuteursToInsert = (auteursRef) => {
        auteursToAdd.value = auteursRef;
    };

    onMounted(() => {
        loadTypeRefs();
        loadAuteurs();

        if (props.id !== undefined) {
            http.getAuteursFromRef(props.id).then((data) => {
                auteursRef.value = data;
            });
        }

        eventBus.on('updateRefBiblioSelected', updateModalData);

        if (props.id !== undefined) {
            http.loadRef(props.id).then((data) => {
                idRef.value = data.idRef;
                titre.value = data.titre;
                document.value = data.document;
                pagination.value = data.pagination;
                annee.value = data.annee;
                motCle.value = data.motCle;
                identification.value = data.identification;
                disponibilite.value = data.disponibilite;
                typeRef.value = data.typeRef;
                auteursRef.value = data.auteurs;
            });
        }

        onBeforeUnmount(() => {
            eventBus.off('updateRefBiblioSelected', updateModalData);
        });
    });

    const loadTypeRefs = () => {
        http.getTypeRefs().then((data) => {
            typeRefs.value = data;
        });
    };

    const loadAuteurs = () => {
        http.getAuteurs().then((data) => {
            auteurs.value = data;
        });
    };

    const emitModifierRef = handleSubmit(async (values) => {
        try {
            const refBiblioData = {
                idRef: localId.value,
                titre: values.titre,
                document: document.value,
                pagination: pagination.value,
                annee: values.annee,
                motCle: motCle.value,
                identification: identification.value,
                disponibilite: disponibilite.value,
                typeRef: values.typeRef,
                auteurs: auteursToAdd.value,
            };
            const data = await http.updateRef(refBiblioData);
            localId.value = data;
            emit('close');
            eventBus.emit('reloadRefs');
            emit('notification',{
                showNotification : true,
                message : "Référence modifiée avec succès!",
                type : "success",
            });
            eventBus.emit('refModifie', refBiblioData);
        } catch (error) {
            emit('notification',{
                showNotification : true,
                message : 'Erreur lors de la modification de la référence.',
                type : "danger",
            })
        }
    });

    return {
        locale,
        titre,
        document,
        pagination,
        annee,
        motCle,
        identification,
        disponibilite,
        typeRefs,
        typeRef,
        auteurs,
        auteursRef,
        auteursToAdd,
        emitModifierRef,
        AuteursToInsert,
        message,
        showNotification,
        type,
        duration
    };
}